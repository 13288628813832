
    <div [formGroup]="filterForm" class="flex flex-col space-y-4">
        <mat-form-field appearance="fill" class="text-sm fuse-mat-rounded fuse-mat-dense xs  ">
            <mat-label>Servizio</mat-label>
            <input type="text"
                   placeholder="Seleziona servizio"
                   aria-label="Number"
                   (click)="onFocusService()"
                   matInput
                   formControlName="service"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onServiceSelected($event)" >
                <mat-option *ngFor="let option of services | async" [value]="option">
                    <span class="text-sm">{{option.name}}</span>
                </mat-option>
            </mat-autocomplete>
            <mat-icon matPrefix class="cursor-pointer" svgIcon="clear"
                      (click)="clearService()"
                      *ngIf="filterForm.get('service').value">
            </mat-icon>
        </mat-form-field>

        <mat-form-field class="fuse-mat-rounded fuse-mat-dense xs text-sm">

            <mat-label>Data:</mat-label>
            <mat-date-range-input [rangePicker]="picker3">
                <input matStartDate formControlName="dateFrom" placeholder="Start date">
                <input matEndDate formControlName="dateTo" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
            <mat-icon matPrefix class="cursor-pointer" svgIcon="clear"
                      (click)="clearDates()"
                      *ngIf="filterForm.get('dateFrom').value ||
                        filterForm.get('dateTo').value">
            </mat-icon>
        </mat-form-field>


        <div class="flex flex-row space-x-2">
            <mat-slide-toggle formControlName="includeOperator" color="warn">Includi operazioni admin
            </mat-slide-toggle>
            <mat-icon svgIcon="mat_solid:info"
                      matTooltip="Ad esempio include le operazioni di ricarica e credito iniziale fatte dagli utenti admin "></mat-icon>

        </div>

        <div class="grid gap-4 grid-cols-2">
            <button mat-button
                    class="mat-focus-indicator ml-3 mat-button mat-button-base mat-primary"
                    (click)="close()">
               <span class="mat-button-wrapper">
                Indietro
               </span>

            </button>

            <button mat-flat-button
                    cdkTrapFocus
                    class="mat-focus-indicator ml-3 mat-flat-button mat-button-base mat-primary"
                    (click)="export()">
               <span class="mat-button-wrapper">
                   Esporta
               </span>

            </button>
        </div>
    </div>

