import { Injectable } from '@angular/core';
import { Apollo, gql, MutationResult } from "apollo-angular";
import { Service } from 'graphql/generated';
import { combineLatest, filter, map, Observable, Subject, takeUntil } from "rxjs";
import { ServiceDTO } from "../dto/dto";
import { BoolOperation, ExpressionBuilder } from "./ExpressionBuilder";
import { WORKAREA_FIELD } from "./workarea-gql.service";


export const SERVICE_FIELD = gql`
    fragment SERVICE_FIELD on service {
        isActive
        showReportPreview
        category
        channel
        dataChannel
        description
        executionPolicy
        internalDescription
        name
        priceDescription
        provider
        reportTemplate
        slug
        submitText
        id
        revision
        workareaId
        fields
        postConfirmButtons
        priceCalculation
        targets
        createdAt
        lastModified

    }
`;


const add = gql`
    ${SERVICE_FIELD}

    mutation AddService($dto: service_insert_input!) {
        insert_service_one(object: $dto) {
            ...SERVICE_FIELD
        }
    }`
const update = gql`
    ${SERVICE_FIELD}

    mutation UpdateService($id: Int!, $dto: service_set_input!) {
        update_service_by_pk(pk_columns: {id: $id}, _set: $dto){
            ...SERVICE_FIELD

        }

    }`

const del = gql `
    ${SERVICE_FIELD}

    mutation DeleteService($id: Int!, $dto: service_set_input!){
        delete_service_by_pk(pk_columns: {id: $id}, _set: $dto){
            ...SERVICE_FIELD
        }
    }`


const getAll = gql`
    ${SERVICE_FIELD}
    ${WORKAREA_FIELD}

    query GetAllService($offset: Int, $limit: Int, $where: service_bool_exp, $sort: [service_order_by!], $includeWa: Boolean = false) {
        service(offset: $offset, limit: $limit, where: $where, order_by: $sort) {
            ...SERVICE_FIELD
            workarea @include(if: $includeWa){
                ...WORKAREA_FIELD
            }
        }
        service_aggregate (where: $where) {
        aggregate {
            count
        }
    }
    }
`;

const getById = gql`
    ${SERVICE_FIELD}
    ${WORKAREA_FIELD}

    query GetServiceById($id: Int!, $includeWa: Boolean = false) {
        service_by_pk(id: $id) {
            ...SERVICE_FIELD
            workarea @include(if: $includeWa){
                ...WORKAREA_FIELD
            }
        }
    }`;


@Injectable({
    providedIn: 'root'
})
export class ServiceGqlService {

    protected destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private apollo: Apollo) {

    }

    getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, sort: any = {createdAt: 'desc'}, includes: { includeWa: boolean } = {includeWa: false}): Observable<{ data: Service[], totalRows: number }> {

        const fetchData = this.apollo
            .watchQuery({
                query: getAll,
                variables: {
                    offset: page * pageSize,
                    limit: pageSize,
                    where: ExpressionBuilder.toGql(filters),
                    sort: [sort],
                    includeWa: includes.includeWa
                },
                fetchPolicy: 'no-cache',

            }).valueChanges.pipe(filter(c => !c.loading));
      
        return combineLatest([fetchData]).pipe(
            takeUntil(this.destroy$),
            filter(x => x[0].data?.['service']),
            map(x => {
                const data = x[0].data?.['service']
                const d = data.map(y => (({__typename, ...o}) => o)(y));
                return {
                    data: d,
                    totalRows: x[0].data['service_aggregate'].aggregate.count
                }
            })
        )
    }

    getById(id: number, includes: { includeWa: boolean } = {includeWa: false}): Observable<ServiceDTO> {
        return this.apollo.watchQuery({
            query: getById,
            variables: {id: id, includeWa: includes.includeWa},
            fetchPolicy: 'no-cache'
        }).valueChanges.pipe(
            filter(x => !x.loading),
            takeUntil(this.destroy$),
            filter(x => !x.loading),
            map(x => {
                    const data = x.data?.['service_by_pk'];
                    const d = (({__typename, ...o}) => o)(data)
                    return d
                }
            )
        );
    }

    //

    add(dto: ServiceDTO): Observable<ServiceDTO> {
        return this.apollo.mutate({
            mutation: add,
            variables: {dto: dto},
            fetchPolicy: 'no-cache',
        }).pipe(map((x: MutationResult) => x.data.insert_service_one))
    }

    update(id: number, dto: ServiceDTO): Observable<ServiceDTO> {
        return this.apollo.mutate({
            mutation: update,
            variables: {id: id, dto: dto},
            fetchPolicy: 'no-cache',

        }).pipe(map((x: MutationResult) => x.data.update_service_by_pk))
    }

    delete(id: number, dto: ServiceDTO): Observable<ServiceDTO> {
        return this.apollo.mutate({
            mutation: del,
            variables: {id: id, dto: dto},
            fetchPolicy: 'no-cache',

        }).pipe(map((x: MutationResult) => x.data.delete_service_by_pk))
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
