import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from "@angular/material/icon";
import { ExportFilterModalComponent } from 'app/modules/admin/company/company-transactions/export-filter-modal/export-filter-modal.component';
import { AllowedRoleDirective } from './allowed-role.directive';
import { ConfirmButtonComponent } from './confirm-button/confirm-button.component';
import { DropAreaDirective } from './drop-area.directive';
import { LoadButtonDirective } from "./load-button.directive";
import { LoadingComponent } from './loading/loading.component';
import { NgLetDirective } from "./ng-let.directive";
import { SearchBarComponent } from './search-bar/search-bar.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatDatepickerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SearchBarComponent,
        LoadingComponent,
        NgLetDirective,
        ConfirmButtonComponent,
        LoadButtonDirective,
        DropAreaDirective,
        AllowedRoleDirective,
        ExportFilterModalComponent
    ],
    declarations: [
        SearchBarComponent,
        LoadingComponent,
        NgLetDirective,
        ConfirmButtonComponent,
        LoadButtonDirective,
        DropAreaDirective,
        AllowedRoleDirective,
        ExportFilterModalComponent
    ]
})
export class SharedModule {
}
