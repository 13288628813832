import {valueReferenceToExpression} from "@angular/compiler-cli/src/ngtsc/annotations/common";

/** OPERATIONS  */

export type OpType =
    '_eq'
    | '_neq'
    | '_gt'
    | '_gte'
    | '_in'
    | '_nin'
    | '_is_null'
    | '_lt'
    | '_lte'
    | '_neq'
    | '_nin'
    | '_like'
    | '_ilike'
    | '_regex';

export type JsonOpType = '_contains';
export type JunctionType = '_and' | '_or';


/** STANDARD OPERATIONS */

export class GenericOperation implements Operation {
    field: string
    type: OpType
    value?: string | number | string[] | number[] | Date | boolean

    constructor(field: string, opType: OpType, value?: string | number | string[] | number[] | Date | boolean) {
        this.field = field
        this.type = opType
        this.value = value;
    }

    visit(): Object {
        let obj = {}
        if (this.field.indexOf('.') < 0) {
            obj[this.field] = {}
            obj[this.field][this.type] = this.value

        } else {
            const split = this.field.split('.')
            let tmp = obj;
            for (let p of split) {
                tmp[p] = {}
                tmp = tmp[p]
            }
            tmp[this.type] = this.value

        }
        return obj
    }
}

export class Like extends GenericOperation {

    constructor(field: string, value?: string | number | string[] | number[]) {
        super(field, '_like', value)
    }
}

export class ILike extends GenericOperation {

    constructor(field: string, value?: string | number | string[] | number[]) {
        super(field, '_ilike', value)
    }
}

export class Gte extends GenericOperation {
    constructor(field: string, value?: string | Date) {
        super(field, '_gte', value)
    }
}

export class Gt extends GenericOperation {
    constructor(field: string, value?: string | Date) {
        super(field, '_gt', value)
    }
}

export class Lte extends GenericOperation {
    constructor(field: string, value?: string | Date) {
        super(field, '_lte', value)
    }
}

export class Lt extends GenericOperation {
    constructor(field: string, value?: string | Date) {
        super(field, '_lt', value)
    }
}

export class Eq extends GenericOperation {

    constructor(field: string, value?: string | number | string[] | number[] | boolean) {
        super(field, '_eq', value)
    }
}
export class NotEq extends GenericOperation {

    constructor(field: string, value?: string | number | string[] | number[] | boolean) {
        super(field, '_neq', value)
    }
}
export class NotIn extends GenericOperation {

    constructor(field: string, value?: string | number | string[] | number[] | boolean) {
        super(field, '_nin', value)
    }
}
export class IsNull extends GenericOperation {

    constructor(field: string) {
        super(field, '_is_null', true)
    }
}
export class IsNotNull extends GenericOperation {

    constructor(field: string) {
        super(field, '_is_null', false)
    }
}
/** JSON OPERATIONS */
export class JsonOperations implements BoolOperation {
    field: string
    type: JsonOpType
    value?: string | number | string[] | number[] | Date | boolean | Object

    constructor(field: string, opType: JsonOpType, value?: string | number | string[] | number[] | Date | boolean | Object) {
        this.field = field
        this.type = opType
        this.value = value;
    }

    visit(): Object {
        let obj = {}
        if (this.field.indexOf('.') < 0) {
            obj[this.field] = {}
            obj[this.field][this.type] = this.value

        } else {
            const split = this.field.split('.')
            let tmp = obj;
            for (let p of split) {
                tmp[p] = {}
                tmp = tmp[p]
            }
            tmp[this.type] = this.value

        }
        return obj
    }
}

export class JsonContains extends JsonOperations {
    constructor(field: string, value?: Object) {
        super(field, '_contains', value)
    }

}


export interface BoolOperation {
    visit(): Object
}

export interface Operation extends BoolOperation {
    field: string,
    type: OpType
    value?: string | number | string[] | number[] | Date | boolean
}

export abstract class Junction implements BoolOperation {
    type: JunctionType
    operations: BoolOperation[] = []

    visit(): Object {
        let obj = {}
        obj[this.type] = []
        for (let op of this.operations) {
            obj[this.type].push(op.visit())
        }
        return obj
    }
}

export class and extends Junction {
    type = '_and' as JunctionType

    add(op: Operation) {
        this.operations.push(op)
        return this
    }

    like(field: string, value: string) {
        this.operations.push(new Like(field, value))
        return this
    }

    ilike(field: string, value: string) {
        this.operations.push(new ILike(field, value))
        return this
    }

    eq(field: string, value: string | number | boolean) {
        this.operations.push(new Eq(field, value))
        return this
    }
    notEq(field: string, value: string | number | boolean) {
        this.operations.push(new NotEq(field, value))
        return this
    }
    notIn(field: string, value: string | number | string[] | number[] ) {
        this.operations.push(new NotIn(field, value))
        return this
    }
    isNull(field: string) {
        this.operations.push(new IsNull(field))
        return this
    }
    isNotNull(field: string) {
        this.operations.push(new IsNotNull(field))
        return this
    }
    gte(field: string, value: string | Date) {
        this.operations.push(new Gte(field, value))
        return this
    }

    gt(field: string, value: string | Date) {
        this.operations.push(new Gt(field, value))
        return this
    }

    lte(field: string, value: string | Date) {
        this.operations.push(new Lte(field, value))
        return this
    }

    lt(field: string, value: string | Date) {
        this.operations.push(new Lt(field, value))
        return this
    }


    btw(field: string, value: string[] | Date[]) {
        if (value.length != 2)
            throw Error("In between filter you mast pass 2 values. Passed " + value.length)
        this.operations.push(new Gte(field, value[0]))
        this.operations.push(new Lte(field, value[1]))
        return this
    }

    jsonContains(field: string, value: Object) {
        this.operations.push(new JsonContains(field, value))
        return this
    }

    or() {
        const op = new or();
        this.operations.push(op)
        return op
    }

    and() {
        const op = new and();
        this.operations.push(op)
        return op
    }
}

export class or extends Junction {
    type = '_or' as JunctionType

    add(op: Operation) {
        this.operations.push(op)
        return this
    }

    like(field: string, value: string) {
        this.operations.push(new Like(field, value))

        return this
    }

    ilike(field: string, value: string) {
        this.operations.push(new ILike(field, value))
        return this
    }

    eq(field: string, value: string | number) {
        this.operations.push(new Eq(field, value))
        return this
    }
    notEq(field: string, value: string | number | boolean) {
        this.operations.push(new NotEq(field, value))
        return this
    }

    isNull(field: string) {
        this.operations.push(new IsNull(field))
        return this
    }
    notIn(field: string, value: string | number | string[] | number[] ) {
        this.operations.push(new NotIn(field, value))
        return this
    }
    isNotNull(field: string) {
        this.operations.push(new IsNotNull(field))
        return this
    }
    gte(field: string, value: string | Date) {
        this.operations.push(new Gte(field, value))
        return this
    }

    gt(field: string, value: string | Date) {
        this.operations.push(new Gt(field, value))
        return this
    }

    lte(field: string, value: string | Date) {
        this.operations.push(new Lte(field, value))
        return this
    }

    lt(field: string, value: string | Date) {
        this.operations.push(new Lt(field, value))
        return this
    }

    btw(field: string, value: string[] | Date[]) {
        if (value.length != 2)
            throw Error("In between filter you mast pass 2 values. Passed " + value.length)
        this.operations.push(new Gte(field, value[0]))
        this.operations.push(new Lte(field, value[1]))
        return this
    }

    or() {
        const op = new or();
        this.operations.push(op)
        return op
    }

    and() {
        const op = new and();
        this.operations.push(op)
        return op
    }
}

export class ExpressionBuilder {

    static getBuilder() {
        return new ExpressionBuilder();
    }

    root() {
        return new and();
    }

    and() {
        return new and();
    }

    or() {
        return new or();
    }


    static toGql(op: BoolOperation) {
        if (!op)
            return {}
        return op.visit()
    }
}
