import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    constructor() {
    }

    getThemePath() {
        return environment.theme ? 'assets/themes/' + environment.theme + "/" : 'assets/images/logo/';
    }
}
