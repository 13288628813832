import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { InitialDataResolver } from './app.resolvers';
// import { LandingHomeComponent } from './modules/landing/home/home.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    {
        path: 'logout',
        pathMatch: 'full',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
    },
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'statistics' },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'companies',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/company/company.module').then(m => m.CompanyModule)
            },
            {
                path: 'workareas',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/workarea/workarea.module').then(m => m.WorkareaModule)
            },
            {
                path: 'history',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/history/history.module').then(m => m.HistoryModule)

            },
            {
                path: 'screeners',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/screeners/screeners.module').then(m => m.ScreenersModule)
            },
            {
                path: 'statistics',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/statistics/statistics.module').then(m => m.StatisticsModule)

            },
            {
                path: 'bundles',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/bundles/bundles.module').then(m => m.BundlesModule)
            },
            {
                path: 'alerts',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/alerts/alerts.module').then(m => m.AlertsModule)
            },
            {
                path: 'users',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)

            },
            {
                path: 'requests',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/requests/requests.module').then(m => m.RequestsModule)

            },
            {
                path: 'services',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/services/services.module').then(m => m.ServicesModule)

            },]
    },
    {
        path: 'no-permissions',
        loadChildren: () => import('app/modules/no-permissions/no-permissions.module').then(m => m.NoPermissionsModule)

    }

];
