import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { take } from 'rxjs';

@Directive({
  selector: '[allowedRoles]'
})
export class AllowedRoleDirective {

  private _allowedRoles: User['appRole'][] = []

  @Input()
  set allowedRoles(roles: User['appRole'][]) {
    this._allowedRoles = roles
    this._updateView()
  }

  constructor(private _viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private _userService: UserService) {
  }

  _updateView() {
    this._userService.user$.pipe(take(1)).subscribe((user: User) => {
      if (this._allowedRoles.includes(user.appRole)) {
        this._viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this._viewContainer.clear();
      }
    });
  }

}
