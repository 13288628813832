import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Service } from 'graphql/generated';
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, Observable, ReplaySubject, switchMap } from "rxjs";
import { catchError, debounceTime, map } from "rxjs/operators";
import { BoolOperation, ExpressionBuilder } from "../../../../../services/graphql/ExpressionBuilder";
import { ServiceGqlService } from "../../../../../services/graphql/service-gql.service";

@Component({
    selector: 'app-export-filter-modal',
    templateUrl: './export-filter-modal.component.html',
    styleUrls: ['./export-filter-modal.component.scss'],
    // providers:[MatDatepickerModule, MatNativeDateModule]
})
export class ExportFilterModalComponent implements OnInit {
    @Input()
    companyId: number

    @Output()
    onExport = new EventEmitter<BoolOperation>()
    filterForm: FormGroup;
    private _serviceSelected: Service
    services: Observable<Service[]>
    services$ = new ReplaySubject()
    private _dateFrom: Date
    private _dateTo: Date


    constructor(private formBuilder: FormBuilder,
                private toastService: ToastrService,
                private serviceService: ServiceGqlService) {
        this.filterForm = this.formBuilder.group({
            dateFrom: null,
            dateTo: null,
            service: null,
            includeOperator: true
        });
        this.filterForm.get('service').valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),

        ).subscribe(x => this.services$.next(x))


        this.services = this.services$.pipe(
            map(x => {
                const exp = ExpressionBuilder.getBuilder().root()
                if (x)
                    exp.ilike('name', '%' + x + '%')

                return exp
            }),
            switchMap(x => this.serviceService.getAll(0, 6, x).pipe(
                catchError((e) => {
                    this.toastService.success('Error on searching data');
                    throw e;
                }),
            )),
            map(x => x.data)
        )
    }

    ngOnInit(): void {
    }

    export() {
        const exp = ExpressionBuilder.getBuilder().root().eq('companyId', this.companyId)
        let dateFrom = this.filterForm.getRawValue().dateFrom
        let dateTo = this.filterForm.getRawValue().dateTo

        if (dateFrom && !dateTo) {
            dateFrom = dateFrom.setHours(0, 0, 0, 0)
            exp.gte('createdAt', moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS'))
        } else if (!dateFrom && dateTo) {
            dateTo = dateTo.setHours(23, 59, 59, 999)
            exp.lte('createdAt', moment(dateTo).format('YYYY-MM-DDTHH:mm:ss.SSS'))
        } else if (dateFrom && dateTo) {
            dateFrom = dateFrom.setHours(0, 0, 0, 0)
            dateTo = dateTo.setHours(23, 59, 59, 999)
            exp.btw('createdAt', [moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS'), moment(dateTo).format('YYYY-MM-DDTHH:mm:ss.SSS')])
        }

        if (!!this.serviceSelected) {
            exp.eq('serviceId', this.serviceSelected.id)
        }
        if(!this.filterForm.getRawValue().includeOperator){
            exp.isNull('operatorId')

        }
        this.onExport.emit(exp)
    }


    get serviceSelected(): Service {
        return this._serviceSelected;
    }
    @Input()
    set serviceSelected(value: Service) {
        this._serviceSelected = value;
        this.updateForm()
    }

    get dateFrom(): Date {
        return this._dateFrom;
    }
    @Input()
    set dateFrom(value: Date) {
        this._dateFrom = value;
        this.updateForm()
    }

    get dateTo(): Date {
        return this._dateTo;
    }

    @Input()
    set dateTo(value: Date) {
        this._dateTo = value;
        this.updateForm()
    }

    clearDates() {
        this.filterForm.patchValue({
            dateFrom: undefined,
            dateTo: undefined,
        })
    }
    onServiceSelected(event: MatAutocompleteSelectedEvent) {
        this.filterForm.get('service').setValue(event.option.value.name);
        this._serviceSelected = event.option.value as Service;

    }
    onFocusService(): void {
        const val = this.filterForm.get('service').value;
        if (!val) {
            this.filterForm.get('service').setValue('');
        }
    }

    clearService() {
        this.filterForm.patchValue({
            service: undefined,
        })
        this._serviceSelected = undefined
    }

    private updateForm() {
        this.filterForm.patchValue({
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            service: this.serviceSelected?.name,
        })
    }

    close() {
        this.onExport.emit(null)
    }
}
