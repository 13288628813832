import {Injectable} from '@angular/core';
import {Apollo, gql, MutationResult} from "apollo-angular";
import {combineLatest, filter, map, Observable, Subject, takeUntil, tap} from "rxjs";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {WorkAreaDTO} from "../dto/dto";



export const WORKAREA_FIELD = gql`
    fragment WORKAREA_FIELD on workarea {
        name
        slug
        id
        config
        createdAt
        lastModified

    }
`;


const add = gql`
    ${WORKAREA_FIELD}

    mutation AddWorkarea($dto: workarea_insert_input!) {
        insert_workarea_one(object: $dto) {
            ...WORKAREA_FIELD
        }
    }`
const update = gql`
    ${WORKAREA_FIELD}

    mutation UpdateWorkarea($id: Int!, $dto: workarea_set_input!) {
        update_workarea_by_pk(pk_columns: {id: $id}, _set: $dto){
            ...WORKAREA_FIELD

        }

    }`


const getAll = gql`
    ${WORKAREA_FIELD}

    query GetAllWorkarea($offset: Int, $limit: Int, $where: workarea_bool_exp ) {
        workarea(offset: $offset, limit: $limit, where: $where, order_by: {createdAt: desc}) {
            ...WORKAREA_FIELD
        }
    }
`;

const getById = gql`
    ${WORKAREA_FIELD}

    query GetWorkareaById($id: Int!) {
        workarea_by_pk(id: $id) {
            ...WORKAREA_FIELD
        }
    }`;


const count = gql`query CountWorkarea( $where: workarea_bool_exp ) {
    workarea_aggregate (where: $where) {
        aggregate {
            count
        }
    }
}`;

@Injectable({
    providedIn: 'root'
})
export class WorkareaGqlService {

    protected destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private apollo: Apollo) {

    }

    getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation): Observable<{ data: WorkAreaDTO[], totalRows: number }> {

        const fetchData = this.apollo
            .watchQuery({
                query: getAll,
                variables: {offset: page * pageSize, limit: pageSize, where: ExpressionBuilder.toGql(filters)},
                fetchPolicy: 'no-cache',

            }).valueChanges.pipe(filter(c => !c.loading));
        const fetchCount = this.apollo
            .watchQuery({
                query: count,
                variables: {where: ExpressionBuilder.toGql(filters)},
                fetchPolicy: 'no-cache',

            }).valueChanges.pipe(filter(c => !c.loading));
        return combineLatest([fetchData, fetchCount]).pipe(
            takeUntil(this.destroy$),
            filter(x => x[0].data?.['workarea']),
            map(x => {
                const data = x[0].data?.['workarea']
                const d = data.map(y => (({__typename, ...o}) => o)(y));
                return {
                    data: d,
                    totalRows: x[1].data['workarea_aggregate'].aggregate.count
                }
            })
        )
    }

    getById(id: number): Observable<WorkAreaDTO> {
        return this.apollo.watchQuery({
            query: getById,
            variables: {id: id},
            fetchPolicy: 'no-cache'
        }).valueChanges.pipe(
            filter(x => !x.loading),
            takeUntil(this.destroy$),
            filter(x => !x.loading),
            map(x => {
                    const data = x.data?.['workarea_by_pk'];
                    const d = (({__typename, ...o}) => o)(data)
                    return d
                }
            )
        );
    }

    //

    add(dto: WorkAreaDTO): Observable<WorkAreaDTO> {
        return this.apollo.mutate({
            mutation: add,
            variables: {dto: dto},
            fetchPolicy: 'no-cache',
        }).pipe(map((x: MutationResult) => x.data.insert_workarea_one))
    }

    update(id: number, dto: WorkAreaDTO): Observable<WorkAreaDTO> {
        return this.apollo.mutate({
            mutation: update,
            variables: {id: id, dto: dto},
            fetchPolicy: 'no-cache',

        }).pipe(map((x: MutationResult) => x.data.update_workarea_by_pk))
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
