// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    theme: "checkimpresa",
    favicon:"favicon.jpeg",
    logo: "logo.png",
    title:"CheckImpresa",
    graphql_endpoint: 'https://hasura.app.checkimpresa.it/v1/graphql',
    ws_graphql: 'wss://hasura.app.checkimpresa.it/v1/graphql',
    auth: {
        domain: "cbi-it.eu.auth0.com",
        clientId: "z6Y7KhhgPnbkV0tsHbkfH0MqjSEj7oQ2",
        audience: 'CBI-API', // <-- will come back to this
        redirectUri: window.location.origin,
        httpInterceptor: {
            allowedList: [
                // Attach access tokens to any calls that start with '/api/'
                '/api/*',
                'https://admin.app.checkimpresa.it/api/*',
                'https://hasura.app.checkimpresa.it/*',
                'https://app.checkimpresa.it/*',
                'https://api.app.checkimpresa.it/*',
                'https://report.app.checkimpresa.it/*',
            ],
        }
    },
    apiUrlBE: 'https://api.app.checkimpresa.it',
    apiTimeout: 60000,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
