import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { TheBalanceGQL } from 'graphql/generated';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private doc: Document,
        private _httpClient: HttpClient,
        private authService: AuthService,
        private balanceService: TheBalanceGQL) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // ---------------------------------------------------------------------------------------------
    /**
        * Get the current logged in user data
        */
    get(): Observable<any> {
        this.authService.user$.pipe(
            filter(u => !!u),
            mergeMap(u => combineLatest({
                user: of(u),
                balance: of({} as any)
                //   this.balanceService.fetch({ _eq: u['https://hasura.io/jwt/claims']?.['x-hasura-company-id'] }, { fetchPolicy: 'no-cache' }).pipe(
                //       map(z => z.data.company_by_pk)
                //    )
            })),
            map(({ user, balance }) => ({
                id: user.sub,
                name: user.full_name,
                email: user.email,
                avatar: user.picture,
                status: 'online',
                appAllowedCompanies: user['https://hasura.io/jwt/claims']?.['x-hasura-allowed-companies'],
                appCompanyId: user['https://hasura.io/jwt/claims']?.['x-hasura-company-id'],
                appCompanyName: balance?.name,
                appId: user['https://hasura.io/jwt/claims']?.['x-hasura-user-id'],
                appRole: user['https://hasura.io/jwt/claims']?.['x-hasura-default-role'],
                appWalletId: balance?.walletId,
                accountBalance: balance?.balances?.accountBalance || 0,
                balance: balance?.balances?.balance || 0
            })),
            tap((user) => {
                this._user.next(user);
            }),
            tap(x => console.log("user", x)),
        )
        .subscribe()
        return of(true)
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    logout() {
        this.authService.logout({
                returnTo: this.doc.location.origin + '/'
        });
    }
}
